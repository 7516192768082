import { defineStore } from 'pinia'
import { Article, getArticleBySlug } from 'ic-js-content'
import { MatchesAdditionalInfo } from '~/assets/types/util'

export const useMatchesStore = defineStore('matches', () => {
	const matchesAdditionalInfo = ref<MatchesAdditionalInfo | null>(null)

	const setMatchesAdditionalInfo = (data: Article) => {
		const { content, sponsors } = data
		const generalSponsors = sponsors || []
		const matches = content.map(({ customContent, sponsor }) => {
			const linkedId = customContent?.fixtureteamLinkedid_string?.linkedIds?.[0]
			const teamId = linkedId?.sourceSystem === 'RUGBYVIZ_RUGBY_UNION_TEAM' ? linkedId.sourceSystemId : undefined
			const matchId = linkedId?.sourceSystem === 'RUGBYVIZ_RUGBY_UNION_MATCH' ? linkedId.sourceSystemId : undefined

			return {
				id: matchId,
				teamId,
				buyTicketsLink: customContent.buyTicketsUrl_string,
				sponsor,
			}
		})

		matchesAdditionalInfo.value = {
			generalSponsors,
			matches,
		}
	}

	const fetchMatchesAdditionalInfo = async () => {
		const response = await getArticleBySlug('do-not-delete-match-tickets-config')
		if (!response?.data?.article) return
		setMatchesAdditionalInfo(response.data.article)
	}

	const matchAdditionalInfo = (matchId: number | string, homeTeamId: string | number) => {
		return matchesAdditionalInfo.value?.matches.find(({ id, teamId }) => id === matchId.toString() ||
			teamId === homeTeamId.toString())
	}

	return {
		matchAdditionalInfo,
		matchesAdditionalInfo,
		fetchMatchesAdditionalInfo,
		setMatchesAdditionalInfo,
	}
})
